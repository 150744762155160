<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    :width="size"
    :height="size"
  >
    <keep-alive>
      <component :is="type" :fill="fill"></component>
    </keep-alive>
  </svg>
</template>
<script>
const LaAngleDown = () => import("./LaAngleDown.vue");
const LaAngleUp = () => import("./LaAngleUp.vue");
const LaCheck = () => import("./LaCheck.vue");
const LaCheckCircle = () => import("./LaCheckCircle.vue");
const LaCheckDouble = () => import("./LaCheckDouble.vue");
const LaClock = () => import("./LaClock.vue");
const LaDownload = () => import("./LaDownload.vue");
const LaExclamation = () => import("./LaExclamation.vue");
const LaFileAlt = () => import("./LaFileAlt.vue");
const LaFileArchive = () => import("./LaFileArchive.vue");
const LaFileAudio = () => import("./LaFileAudio.vue");
const LaFileCsv = () => import("./LaFileCsv.vue");
const LaFileExcel = () => import("./LaFileExcel.vue");
const LaFilePdf = () => import("./LaFilePdf.vue");
const LaFilePowerpoint = () => import("./LaFilePowerpoint.vue");
const LaFileVideo = () => import("./LaFileVideo.vue");
const LaFileWord = () => import("./LaFileWord.vue");
const LaInfoCircle = () => import("./LaInfoCircle.vue");
const LaImage = () => import("./LaImage.vue");
const LaMapMarkerAlt = () => import("./LaMapMarkerAlt.vue");
const LaMapPin = () => import("./LaMapPin.vue");
const LaPaperclip = () => import("./LaPaperclip.vue");
const LaPlay = () => import("./LaPlay.vue");
const LaRedoAlt = () => import("./LaRedoAlt.vue");
const LaSmile = () => import("./LaSmile.vue");
const LaSms = () => import("./LaSms.vue");
const LaTimes = () => import("./LaTimes.vue");
const LaTimesCircle = () => import("./LaTimesCircle.vue");
const LaTrashAlt = () => import("./LaTrashAlt.vue");
const LaUser = () => import("./LaUser.vue");
const LaUserSlash = () => import("./LaUserSlash.vue");

export default {
  components: {
    LaAngleDown,
    LaAngleUp,
    LaCheck,
    LaCheckCircle,
    LaCheckDouble,
    LaClock,
    LaDownload,
    LaExclamation,
    LaFileAlt,
    LaFileArchive,
    LaFileAudio,
    LaFileCsv,
    LaFileExcel,
    LaFilePdf,
    LaFilePowerpoint,
    LaFileVideo,
    LaFileWord,
    LaInfoCircle,
    LaImage,
    LaMapMarkerAlt,
    LaMapPin,
    LaPaperclip,
    LaPlay,
    LaRedoAlt,
    LaSmile,
    LaSms,
    LaTimes,
    LaTimesCircle,
    LaTrashAlt,
    LaUser,
    LaUserSlash
  },
  props: {
    type: { type: String, default: "" },
    size: { type: String, default: "12" },
    fill: { type: String, default: "currentColor" }
  }
};
</script>
